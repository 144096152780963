var exports = {};

exports = function (hljs) {
  return {
    contains: [// IP
    {
      className: "number",
      begin: "\\b\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}\\.\\d{1,3}(:\\d{1,5})?\\b"
    }, // Other numbers
    {
      className: "number",
      begin: "\\b\\d+\\b",
      relevance: 0
    }, // Requests
    {
      className: "string",
      begin: "\"(GET|POST|HEAD|PUT|DELETE|CONNECT|OPTIONS|PATCH|TRACE)",
      end: "\"",
      keywords: "GET POST HEAD PUT DELETE CONNECT OPTIONS PATCH TRACE",
      illegal: "\\n",
      relevance: 10
    }, // Dates
    {
      className: "string",
      begin: /\[/,
      end: /\]/,
      illegal: "\\n"
    }, // Strings
    {
      className: "string",
      begin: "\"",
      end: "\"",
      illegal: "\\n"
    }]
  };
};

export default exports;